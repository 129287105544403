export const white = '#FFFFFF'
export const black = '#000000'

export const green = {
  green1: '#31a4ed',
  green2: '#31a4ed'
}

export const red = {
  red1: '#31a4ed',
  red2: '#fff'
}

export const yellow = {
  yellow1: '#FFE270',
  yellow2: '#31a4ed'
}
