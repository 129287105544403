import base from './base'

export default {
  ...base,
  colors: {
    ...base.colors,
    shadow1: '#31a4ed',
    text1: '#31a4ed',
    text2: '#31a4ed',
    text3: '#888D9B',
    text4: '#31a4ed',

    // backgrounds / greys
    bg0: 'black',
    bg1: 'black',
    bg2: 'black',
    bg3: 'black',
    bg4: '#fff',
    bg5: '#31a4ed',
    modalBG: 'rgba(0,0,0,0.3)',
    advancedBG: '#31a4ed',

    //primary colors
    primary1: '#31a4ed',
    primary4: '#31a4ed',
    primary5: '#31a4ed',

    // color text
    primaryText1: 'black',
    buttonText: 'black',

    // secondary colors
    secondary1: '#fff'
  }
}
